import React from 'react';

import LegalScene from 'app/components/LegalScene';

const DisclaimerScene = () => (
    <LegalScene title="Disclaimer">
        <p>
            Wot No Mushy Peas is in no way associated with or endorsed by Gainsborough Trinity
            football club. It is owned and operated purely by fans of the club.
        </p>
        <p>
            Although every attempt has been made to ensure the accuracy of the information provided,
            we can not guarantee that there are no mistakes. None of the contributors, owners,
            administrators or anyone else connected with Wot No Mushy Peas can be responsible for
            the appearance of any inaccurate information, or for your use of the information
            contained in or linked from this website.
        </p>
    </LegalScene>
);

export default DisclaimerScene;
